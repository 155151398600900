<template>
  <div style="padding: 10px">
    <div style="background: #fff; border-radius: 8px; padding: 20px;">
      <div class="query-c">
        <!-- 查询： -->
        <!-- <Input placeholder="请输入用户名称" v-model="name" style="width: auto;margin-right:10px;" /> -->
        <!-- <Input placeholder="请输入用户手机号" v-model="phone" style="width: auto;margin-right:10px;" /> -->
        <!-- <DatePicker v-model="date" format="yyyy-MM-dd hh:mm:ss" @on-change="changeDate" type="daterange"
          placement="bottom-end" placeholder="Select date" style="width: 200px;margin-right:10px;" /> -->
          <Button type="primary" size="small" @click="showInfoDialog">新增</Button>
          <!-- <Button type="primary" size="small" @click="getTreeTypeList">查询</Button> -->
      </div>
      <br>
      <Table max-height="670" border stripe :columns="columns" :data="data">
        <template #main_image_url="{ row }">
          <div>
            <img :src="`https://${row.main_image_url}`" :fit="'cover'" width="100px" height="100px"  />
          </div>
        </template>
        <template #price="{ row }">
          <div>{{ row.price }}</div>
        </template>
        <template #createdAt="{ row }">
          <div>{{ timeFormatter(row.createdAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #updatedAt="{ row }">
          <div>{{ timeFormatter(row.updatedAt, '{year}-{month}-{day} {hour}:{minute}:{second}') }}</div>
        </template>
        <template #action="{ row, index }">
          <div>
            <Button class="operate-btn" type="primary" size="small"
              @click="showInfoDialog({ row, index })">编辑</Button>
            <Button class="operate-btn" type="error" size="small"
              @click="deleteItem({ row, index })">删除</Button>
          </div>
        </template>
      </Table>
      <br>
      <Page :total="total" :page-size="pageSize" show-elevator @on-change="changePage" />
    </div>

    <Modal v-model="treeVisible" title="果树信息">
      <Form ref="treeInfo" :model="treeInfo" :label-width="100" :rules="ruleInline">
        <FormItem prop="name" label="果树名称">
          <Input type="text" v-model="treeInfo.name" placeholder="果树名称" />
        </FormItem>
        <FormItem prop="description" label="描述">
          <Input type="text" v-model="treeInfo.description" placeholder="描述" />
        </FormItem>
      </Form>
      <template #footer>
        <Button  size="small"  @click="cancel">取消</Button>
        <Button type="primary" size="small"  @click="updateTreeType">确认</Button>
      </template>
    </Modal>
  </div>
</template>

<script>
import { getTreeTypeList, createTreeType, updateTreeType, deleteTreeTypeById } from '@/api'
import { formatterPrice, timeFormatter } from '@/utils'

export default {
    name: 'treeConfig',
    data() {
        const validateFile = (rule, value, callback) => {
            console.log(value)
            if (!this.treeInfo.main_image_url || !this.treeInfo.sub_image_url1 || !this.treeInfo.sub_image_url2) {
                return callback(new Error('请上传3张展示图！'))
            }
            callback()
        }
        return {
            columns: [
                {
                    title: '序号',
                    width: 80,
                    key: 'index',
                },
                {
                    title: '果树名称',
                    key: 'name',
                },
                {
                    title: '描述',
                    key: 'description',
                },
                {
                    title: '创建时间',
                    slot: 'createdAt',
                    width: 200,
                },
                {
                    title: '更新时间',
                    slot: 'updatedAt',
                    width: 200,
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 150,
                    align: 'center',
                },
            ],
            data: [],
            total: 0,
            ruleInline: {
                name: [{ required: true, message: '不能为空', trigger: 'blur' }],
                description: [{ required: true, message: '描述为空', trigger: 'blur' }],
                fileList: [{ required: true, validator: validateFile, trigger: 'blur' }],
            },
            treeInfo: {},
            treeVisible: false,
            page: 1,
            pageSize: 10,
            phone: '',
            name: '',
            fileList: [],
            date: [new Date(new Date().getTime() - 3600000 * 24 * 30), new Date()],
        }
    },
    mounted() {
        this.getTreeTypeList()
    },
    methods: {
        timeFormatter(date, fmt) {
            return timeFormatter(date, fmt)
        },
        changeDate(date) {
            this.date = date
        },
        changePage(page) {
            console.log(page)
            this.page = page
            this.getTreeTypeList()
        },
        async getTreeTypeList() {
            const param = {
                page: this.page,
                pageSize: this.pageSize,
                // startDate: this.date[0],
                // endDate: this.date[1],
                name: this.name,
                // phone: this.phone,
            }
            const { success, data: { data, total } } = await getTreeTypeList(param)
            if (success) {
                this.total = total
                this.data = data.map((i, idx) => {
                    i.index = (this.page - 1) * this.pageSize + idx + 1
                    i.price = formatterPrice(i.price)
                    return i
                })
            }
        },
        showInfoDialog({ row, index }) {
            console.log(index)
            this.$refs.treeInfo.resetFields()
            this.fileList = []
            this.treeVisible = true
            this.treeInfo = row ? JSON.parse(JSON.stringify(row)) : {}
            if (this.treeInfo.main_image_url) {
                this.fileList.push({ status: 'finished', image_url: this.treeInfo.main_image_url })
            }
            if (this.treeInfo.sub_image_url1) {
                this.fileList.push({ status: 'finished', image_url: this.treeInfo.sub_image_url1 })
            }
            if (this.treeInfo.sub_image_url2) {
                this.fileList.push({ status: 'finished', image_url: this.treeInfo.sub_image_url2 })
            }
        },
        cancel() {
            this.treeInfo = {}
            this.fileList = []
            this.treeVisible = false
        },
        deleteItem({ row, index }) {
            console.log(index)
            console.log(row)
            console.log(this.$Modal)
            this.$Modal.confirm({
                title: '确认删除',
                content: '<p>确认删除</p>',
                onOk: async () => {
                    const param = {
                        id: row.id,
                    }
                    const { success, data } = await deleteTreeTypeById(param)
                    if (success) {
                        console.log(data)
                        this.getTreeTypeList()
                    }
                    this.$Message.info('Clicked ok')
                },
                onCancel: () => {
                    this.treeInfo = {}
                },
            })
        },
        updateTreeType() {
            this.$refs.treeInfo.validate(async (valid) => {
                if (valid) {
                    const param = {
                        name: this.treeInfo.name,
                        description: this.treeInfo.description,
                        main_image_url: this.fileList[0]?.image_url ?? '',
                        sub_image_url1: this.fileList[1]?.image_url ?? '',
                        sub_image_url2: this.fileList[2]?.image_url ?? '',
                        details: this.treeInfo.details,
                        price: this.treeInfo.price * 100,
                        remaining_stock: this.treeInfo.remaining_stock,
                        total_stock: this.treeInfo.total_stock,
                    }
                    if (this.treeInfo.id) {
                        param.id = this.treeInfo.id
                    }
                    const { success, data } = this.treeInfo.id ? await updateTreeType(param) : await createTreeType(param)
                    if (success) {
                        console.log(data)
                        this.treeInfo = {}
                        this.fileList = []
                        this.treeVisible = false
                        this.getTreeTypeList()
                    }
                }
            })
        },
        uploadSuccess(list) {
            console.log(list)
            this.$set(this.treeInfo, 'main_image_url', list[0].image_url ?? '')
            this.$set(this.treeInfo, 'sub_image_url1', list[1]?.image_url ?? '')
            this.$set(this.treeInfo, 'sub_image_url2', list[2]?.image_url ?? '')
        },
        uploadRemove(file) {
            console.log(file)
            this.fileList.splice(this.fileList.indexOf(file), 1)
        },
    },
}
</script>

<style scoped>
.operate-btn {
  margin-bottom: 10px;

}


.operate-btn+.operate-btn {
  margin-left: 5px;
}
</style>
